export default {
    orgSchema : (content) => `{
      "@context": "https://schema.org/",
      "@type": "Organization",
      "@id": "${process.env.hostBaseUrl}/",
      "name": "21Spades",
      "url": "${process.env.hostBaseUrl}/",
      "legalName": "21Spades INC",
      "foundingDate": "2022",
      "description": "${content && content.pageMetaDescription ? (content.pageMetaDescription).replace(/"/g, '&quot;') : ''}",
      "sameAs": [
        "https://www.facebook.com/UPYOcom",
        "https://twitter.com/upyocom",
        "https://www.instagram.com/upyo_com",
        "https://www.linkedin.com/company/upyocom"
      ],
      "logo": {
        "@type": "ImageObject",
        "@id": "${process.env.hostBaseUrl}/logo_light.png",
        "url": "${process.env.hostBaseUrl}/logo_light.png",
        "name": "UpYo NFT"
      }
    }`,
    websiteSchema : `{
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "21Spades",
      "url": "${process.env.hostBaseUrl}/"
    }`,
    aboutSchema : ({ currLocale, content }) => `{
            "@context": "https://schema.org/",
            "@type": "AboutPage",
            "url": "${process.env.hostBaseUrl}${currLocale && currLocale !== '' ? '/' + currLocale : ''}/nft/about",
            "name": "${content && content.pageName ? content.pageName : 'about'}",
            "description": "${content && content.pageMetaDescription ? (content.pageMetaDescription).replace(/"/g, '&quot;') : ''}"
        }`,

    crummbSchema : (props) => `{
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "${props.mainLinkTitle}",
                    "item": {
                        "@type": "Thing",
                        "@id": "${process.env.hostBaseUrl}${props.currLocale && props.currLocale !== '' ? '/' + props.currLocale : ''}${props.mainLink}"
                    }
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "${props.linkTitle1}",
                    "item": {
                        "@type": "Thing",
                        "@id": "${process.env.hostBaseUrl}${props.currLocale && props.currLocale !== '' ? '/' + props.currLocale : ''}${props.link1}"
                    }
                }
                ${props.subLink ?
        `,{
                        "@type": "ListItem",
                        "position": 3,
                        "name": "${props.linkTitle2 && props.linkTitle2 !== '' ? props.linkTitle2 : ''}",
                        "item": {
                            "@type": "Thing",
                            "@id": "${process.env.hostBaseUrl}${props.currLocale && props.currLocale !== '' ? '/' + props.currLocale : ''}${props.link2}"
                        }
                    }` :
        ''
    }
            ]
        }`,

    faqSchema : ({pageName, faqData}) => faqData && faqData.length > 0 ? `{
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "name": "${pageName} FAQs",
                "mainEntity": [
                    ${faqData.map(element =>
        `{
                            "@type": "Question",
                            "name": "${(element.question).replace(/"/g, '&quot;')}",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "${((element.answer).replace(/"/g, '&quot;')).replace(/<(.|\n)*?>/g, '')}"
                            }
                        }`
    )}
                ]
            }` : '',

    profileSchema : (curreLocale, router) => `{
            "@context": "https://schema.org/",
            "@type": "ProfilePage",
            "mainEntity": {
                "@type": "Person",
                "name": "Enrico Cole",
                "image": "${process.env.hostBaseUrl}/avatar-creator.png",
                "url": "${process.env.hostBaseUrl}${currLocale && currLocale !== '' ? '/' + currLocale : ''}${(router && router.asPath ? router.asPath : '/nft')}",
                "sameAs": [
                    "https://www.facebook.com/UPYOcom",
                    "https://twitter.com/upyocom",
                    "https://www.instagram.com/upyo_com",
                    "https://www.linkedin.com/company/upyocom"
                ]
            }
        }`,

    newsSchema : (pageUrl, article, meta, category, author) => `{
    "@context": "https://schema.org/",
    "@type": "NewsArticle",
    "inLanguage": "ar",
    "url": "${pageUrl}",
    "headline": "${article && article.title ? article.title : ''}",
    "description": "${meta && meta.meta_description ? meta.meta_description : ''}",
    "datePublished": "${article && article.date ? article.date : ''}",
    "dateModified": "${article && article.modified ? article.modified : ''}",
    "articleSection": "${category}",
    "wordCount": ${article && article.content.trim().replace(/\s+/g, ' ').split(' ').length},
    "image": "${article.featuredImage ? article.featuredImage.node.sourceUrl : process.env.hostBaseUrl + '/21Spades-default.png'}",
    "articleBody": "${article && article.content && article.content !== '' ? article.content.replace(/(<([^>]+)>)/ig, '') : ''}",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${pageUrl}",
      "name": "${article && article.title ? article.title : ''}"
    },
    "publisher" : {
      "@type": "Organization",
      "name": "21Spades",
      "logo": {
        "@type": "ImageObject",
        "url": "${process.env.hostBaseUrl}/logo_light.png",
        "width": 1313,
        "height": 344
      }
    },
    "author": {
      "@type": "Person",
      "name": "${author && author.name ? author.name : `21Spades Team`}",
      "url": "${process.env.hostBaseUrl}/ar/editor/${author && author.slug ? author.slug : `21Spades-team`}"
    }
  }`
}